.top-bar {
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 3px 9px 1px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-around;
  padding: 9px 0;
}

.top-bar-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
  margin: 0;
}

.top-bar-menu li {
  display: table-cell;
  position: relative;
}

.top-bar-menu a {
  color: #010000;
  margin-right: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.top-bar-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #ee7330;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.top-bar-menu a:hover:after {
  width: 100%;
  left: 0;
  color: #ee7330;
}

.top-bar-menu .topbar-btn {
  background: #ee7330;
  padding: 32px 0px;
  position: absolute;
  bottom: -32px;
  margin: 0;
  width: 160px;
  color: #ffffff;
  font-weight: 400;
}

.top-bar-menu .topbar-btn:hover {
  background-color: #c75e25;
}

.top-bar-menu .topbar-btn:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #c75e25;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
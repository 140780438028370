.header {margin-top: 50px;}

.header>.col-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.header>.col-left h5 {
  width: 340px;
  font-size: clamp(16px, 2vw, 30px);
  font-weight: bold;
}

.header>.col-left p {
  width: 340px;
  margin: 0;
  color: #646464;
  line-height: 1.3;
  font-size: clamp(12px, 2vw, 18px);;
}

.benefit-col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefit-col-right h5 {
  font-size: clamp(16px, 2vw, 35px);
}

.benefit-col-right ul {
  list-style: none;
}

.benefit-col-right ul li {
  font-size: clamp(16px, 2vw, 24px);
}

.benefit-col-right ul li::before {
  content: "\2022";
  color: #ee7330;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.footer {
  background: #004984;
  padding: 60px 0 20px;
  margin-top: 140px;
}

.footer .left-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer h5 {
  color: #fff;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 15px;
}

.under-below {
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

.footer .right-col img {
  margin-right: 10px;
}

.footer .right-col div {
  margin-bottom: 15px;
}

.footer p {
  color: #fff;
  width: 300px;
  margin: 0;
}

.footer a {
  margin-right: 15px;
  color: #fff;
  cursor: pointer;
}

.footer .copyright {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.footer .copyright p {
  text-align: center;
  padding-top: 20px;
}
.columns {
  display: flex;
  justify-content: space-around;
  padding: 0 200px;
}

.column-what {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-what dt {
  background: #fae9cb;
  border-radius: 1000px;
  width: 150px;
  height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.column-what dd {
  color: #7a7a7a;
  font-weight: 600;
}

.orange-hr {
  background: #ee7330;
  height: 2px;
  border: none;
  width: 650px;
  margin-top: 80px;
  margin-bottom: 80px;
}
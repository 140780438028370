.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solution  p {
  color: #6e6e6e;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 0;
}

.solution section {
  max-width: 700px;
}

.solution section dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.solution  dt {
  background: #ffffff;
  height: 110px;
  padding: 20px;
  width: 190px;
  border-radius: 10px;
  box-shadow: 2px 0px 5px 1px rgb(0 0 0 / 25%);
  margin-bottom: 30px;
  margin-right: 20px;
}

.solution dt {
  border-left: 12px solid #ee7330;
}

.solution a {
  color: #ee7330;
  font-weight: bold;
}

.solution hr {
  border: none;
  border-left: 2px dashed #6e6e6e;
  height: 80px;
  width: 1px;
}

.solution hr:last-child {
  border: none;
  border-left: 2px dashed transparent;
  height: 0px;
}
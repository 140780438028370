@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-bar {
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 3px 9px 1px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-around;
  padding: 9px 0;
}

.top-bar-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
  margin: 0;
}

.top-bar-menu li {
  display: table-cell;
  position: relative;
}

.top-bar-menu a {
  color: #010000;
  margin-right: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.top-bar-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #ee7330;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.top-bar-menu a:hover:after {
  width: 100%;
  left: 0;
  color: #ee7330;
}

.top-bar-menu .topbar-btn {
  background: #ee7330;
  padding: 32px 0px;
  position: absolute;
  bottom: -32px;
  margin: 0;
  width: 160px;
  color: #ffffff;
  font-weight: 400;
}

.top-bar-menu .topbar-btn:hover {
  background-color: #c75e25;
}

.top-bar-menu .topbar-btn:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #c75e25;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.header {margin-top: 50px;}

.header>.col-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.header>.col-left h5 {
  width: 340px;
  font-size: clamp(16px, 2vw, 30px);
  font-weight: bold;
}

.header>.col-left p {
  width: 340px;
  margin: 0;
  color: #646464;
  line-height: 1.3;
  font-size: clamp(12px, 2vw, 18px);;
}

.benefit-col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefit-col-right h5 {
  font-size: clamp(16px, 2vw, 35px);
}

.benefit-col-right ul {
  list-style: none;
}

.benefit-col-right ul li {
  font-size: clamp(16px, 2vw, 24px);
}

.benefit-col-right ul li::before {
  content: "\2022";
  color: #ee7330;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.columns {
  display: flex;
  justify-content: space-around;
  padding: 0 200px;
}

.column-what {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-what dt {
  background: #fae9cb;
  border-radius: 1000px;
  width: 150px;
  height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.column-what dd {
  color: #7a7a7a;
  font-weight: 600;
}

.orange-hr {
  background: #ee7330;
  height: 2px;
  border: none;
  width: 650px;
  margin-top: 80px;
  margin-bottom: 80px;
}
.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solution  p {
  color: #6e6e6e;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 0;
}

.solution section {
  max-width: 700px;
}

.solution section dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.solution  dt {
  background: #ffffff;
  height: 110px;
  padding: 20px;
  width: 190px;
  border-radius: 10px;
  box-shadow: 2px 0px 5px 1px rgb(0 0 0 / 25%);
  margin-bottom: 30px;
  margin-right: 20px;
}

.solution dt {
  border-left: 12px solid #ee7330;
}

.solution a {
  color: #ee7330;
  font-weight: bold;
}

.solution hr {
  border: none;
  border-left: 2px dashed #6e6e6e;
  height: 80px;
  width: 1px;
}

.solution hr:last-child {
  border: none;
  border-left: 2px dashed transparent;
  height: 0px;
}
.footer {
  background: #004984;
  padding: 60px 0 20px;
  margin-top: 140px;
}

.footer .left-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer h5 {
  color: #fff;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 15px;
}

.under-below {
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
}

.footer .right-col img {
  margin-right: 10px;
}

.footer .right-col div {
  margin-bottom: 15px;
}

.footer p {
  color: #fff;
  width: 300px;
  margin: 0;
}

.footer a {
  margin-right: 15px;
  color: #fff;
  cursor: pointer;
}

.footer .copyright {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.footer .copyright p {
  text-align: center;
  padding-top: 20px;
}
body, head {
  background-color: #fdfdfd;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.pr-50 {padding-right: 50px;}

.title-div {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 50px;
}

.my-container {
  margin: 0 27rem;
}

.responsive_img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.manual {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manual .manual-div {
  border: 3px solid #004984;
  border-radius: 10px;
  width: 170px;
  height: 170px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;
}

.manual .manual-div p {
  color: #585858;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.circle {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -22px;
  background: #004984;
  color: #ffff;
  padding: 9px;
}

.input-email,
.input-email:active,
.input-email:focus-visible {
  border: 2px solid #ee7330;
  outline-color: #ee7330;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.btn-email {
  border-radius: 5px;
  border: none;
  padding: 3px;
  background: #ee7330;
  color: #fff;
  width: 100px;
}

.orange-p {
  color: #ee7330 !important;
  font-weight: 500;
}

.input-box {
  margin-top: 10px;
}

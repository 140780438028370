@import url('~bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './top-bar.css';
@import './header.css';
@import './column.css';
@import './solution.css';
@import './footer.css';

body, head {
  background-color: #fdfdfd;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.pr-50 {padding-right: 50px;}

.title-div {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 50px;
}

.my-container {
  margin: 0 27rem;
}

.responsive_img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.manual {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manual .manual-div {
  border: 3px solid #004984;
  border-radius: 10px;
  width: 170px;
  height: 170px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;
}

.manual .manual-div p {
  color: #585858;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.circle {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -22px;
  background: #004984;
  color: #ffff;
  padding: 9px;
}

.input-email,
.input-email:active,
.input-email:focus-visible {
  border: 2px solid #ee7330;
  outline-color: #ee7330;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.btn-email {
  border-radius: 5px;
  border: none;
  padding: 3px;
  background: #ee7330;
  color: #fff;
  width: 100px;
}

.orange-p {
  color: #ee7330 !important;
  font-weight: 500;
}

.input-box {
  margin-top: 10px;
}